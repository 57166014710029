<template>
  <UiCheckbox
    id="saveSearchOption"
    v-bind="$attrs"
    :model-value="props.modelValue"
    name="saveSearchOption"
    type="checkbox"
    variant="primary"
    :container-class="['items-center py-4 border-t border-t-blue-450 mt-1.5', props?.containerClass ?? '']"
    :label-visible="true"
    @change="handleCheckboxCheck"
  >
    <span class="text-sm">{{
      t('remember_my_choice')
    }}</span>
  </UiCheckbox>
</template>

<script setup lang="ts">
const props = defineProps<{ modelValue: boolean, containerClass?: string }>()
const emit = defineEmits<{
  (e: 'update:model-value', value: boolean): void
}>()

const { t } = useI18n()

function handleCheckboxCheck(event: Event) {
  const isChecked = (event.target as HTMLInputElement).checked
  emit('update:model-value', isChecked)
}
</script>
